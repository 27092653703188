import React, { Component } from "react"
import { navigate } from "gatsby-link"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: "",
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: "SUCCESS" })
      } else {
        this.setState({ status: "ERROR" })
      }
    }
    xhr.send(data)
    // e.preventDefault()
    // const form = e.target
    // fetch('/', {
    //   method: 'POST',
    //   headers: {
    //     Accept: "application/x-www-form-urlencoded;charset=UTF-8",
    //     "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    //   },
    //   body: encode({
    //     "form-name": form.getAttribute("name"),
    //     ...this.state,
    //   }),
    // })
    //   .then(() => {
    //     navigate(form.getAttribute("action"))
    //   })
    //   .catch(error => alert(error))
  }

  render() {
    const { status } = this.state
    return <form name="contact" method="post" action="https://formspree.io/maypabpl" onSubmit={this.handleSubmit}>
        <div className="columns">
          <div className="column is-3 ">
            <h1 id="form-white-font" className="title">
              How can we
              <br /> help you?
            </h1>
          </div>
          <div className="column is-5">
            <div className="field">
              <label className="label" id="form-white-font">
                Your Name: <input className="input" type="text" name="name" onChange={this.handleChange} id="name" required={true} />
              </label>
            </div>
            <div className="field">
              <label className="label" id="form-white-font">
                Your Email: <input className="input" type="email" name="email" onChange={this.handleChange} id="email" required={true} />
              </label>
            </div>
            <div className="field">
              <label className="label" id="form-white-font">
                Your Phone Number: <input className="input" type="tel" name="phone" onChange={this.handleChange} id="phone" required={false} />
              </label>
            </div>
            <div className="field is-grouped">
              <div className="control">
                {status === "SUCCESS" ? <p id="form-white-font">
                    Thanks!
                  </p> : <button className="button is-link" type="submit">
                    Submit
                  </button>}
                {status === "ERROR" && <p id="form-white-font">
                    Ooops! There was an error.
                  </p>}
              </div>
            </div>
          </div>
          <div className="column">
            <div className="field">
              <label className="label" id="form-white-font">
                Message: <textarea className="textarea" onChange={this.handleChange} name="inquiryMessage" id="inquiryMessage" />
              </label>
            </div>
            <label label="form-white-font" id="form-white-font">
              {" "}
            </label>
          </div>
        </div>
      </form>
  }
}
