/**
 * Created by vaibhav on 31/3/18
 */
import React, { Component, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import PageWrapper from "../../layouts/PageWrapper"
import NoSSR from "react-no-ssr"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Setia from "./setiawangsa.png"
import Mukim from "./mukimhutan.png"
import BGImg from "./IMG1.jpg"
import BackgroundSlider from "react-background-slider"
import HomeLogo from "../../components/HomeLogo"
import ContactForm from "../../components/ContactForm"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}


const ContactUsPage = () => {

  const [browser, setBrowser] = useState(false)
  useEffect(() => {
    // This code is executed in the browser, not the server (same as componentDidMount)
    setBrowser(true)
  }, [])

  // This would cause the server build to not output any HTML
  if (!browser) return null

  return (
    <PageWrapper>
      <div>
        <NoSSR>
          <BackgroundSlider images={[BGImg]} duration={10000} transition={0} />
        </NoSSR>
        <Helmet>
          <title>Contact Us - EA Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <HomeLogo />
                  <h1 className="title is-pl-3" id="title-line">
                    Contact Us
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <Tabs>
              <TabList className="column">
                <Tab>Corporate Office</Tab>
                <Tab>Port Office</Tab>
              </TabList>
              <TabPanel>
                <div className="columns" style={{ marginTop: `0px` }}>
                  <div
                    className="column is-ml-4"
                    style={{ backgroundColor: ` #0A4DA4` }}
                  >
                    <ContactForm />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-4 is-offset-2">
                    <div className="contactcard">
                      <div className="card-image">
                        <a
                          target="_blank"
                          href="https://www.google.com/maps/place/E.A+Technique+(M)+Berhad/@3.1796294,101.7416504,17z/data=!3m1!4b1!4m5!3m4!1s0x31cc379ae1352329:0x3259c65e859553da!8m2!3d3.1796294!4d101.7438391"
                        >
                          <figure className="image is-4by3">
                            <img src={Setia} />
                          </figure>
                        </a>
                      </div>
                      <div className="card-content">
                        <div className="content">
                          <h1 className="title is-6">
                            E.A. TECHNIQUE (M) BERHAD
                          </h1>
                          <p>Address (Business Contact)</p>
                          <p>
                            Setiawangsa Business Suites
                            <br />
                            Unit C-3A-3A, No.2 Jalan Setiawangsa 11
                            <br />
                            Taman Setiawangsa
                            <br />
                            54200 Kuala Lumpur, Malaysia
                          </p>
                          <p>
                            T +603 4252 5422 (Hunting)
                            <br />
                            F +603 4252 2163 (Account/Admin Dept)
                            <br />
                            +603 4251 2985 (Operation)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="column is-4">
                    <div className="contactcard">
                      <div className="card-image">
                        <a
                          target="_blank"
                          href="https://www.google.com/maps?q=Lot+PT8436-A+Mukim+Hutan+Melintang+36400+Daerah+Hilir+Perak+Perak+Darul+Ridzuan.&um=1&ie=UTF-8&sa=X&ved=0ahUKEwi4tqaD_pDfAhWZeisKHfE1CDkQ_AUIDigB"
                        >
                          <figure className="image is-4by3">
                            <img src={Mukim} />
                          </figure>
                        </a>
                      </div>
                      <div className="card-content">
                        <div className="content">
                          <h1 className="title is-6">
                            Johor Shipyard and Engineering Sdn Bhd
                          </h1>
                          <p>Address (Business Contact)</p>
                          <p>
                            Lot PT8436-A <br />
                            Mukim Hutan Melintang
                            <br />
                            36400 Daerah Hilir Perak <br />
                            Perak Darul Ridzuan
                          </p>
                          <p>
                            T +605 641 2514 <br />
                            F +605 641 3679 <br />E jse@johorshipyard.com.my
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <section className="section" id="port-section">
                  <div className="columns is-multiline">
                    <div className="column is-6">
                      <h1 className="title is-6" id="event-title">
                        PROJECT OFFICE
                      </h1>
                      <div className="columns">
                        <div className="column">
                          Setiawangsa Business Suites
                          <br />
                          Unit B-1-1
                          <br />
                          No.2 Jalan Setiawangsa 11
                          <br />
                          Taman Setiawangsa
                          <br />
                          54200 Kuala Lumpur, Malaysia
                          <br />
                          <br />
                        </div>
                        <div className="column">
                          T +603 4252 5422
                          <br />F +603 4266 0487
                        </div>
                      </div>
                    </div>

                    <div className="column is-6">
                      <h1 className="title is-6" id="event-title">
                        NORTHPORT, PELABUHAN KLANG OFFICE
                      </h1>
                      <div className="columns">
                        <div className="column">
                          No. 37, Lintang Sultan Mohammad 1B
                          <br />
                          Pusat Perdagangan Bandar
                          <br />
                          Sultan Suleiman, 42000 Port Klang,
                          <br />
                          Selangor Darul Ehsan
                        </div>
                        <div className="column">
                          T +6012 502 9224
                          <br />E &nbsp;
                          <a
                            id="footer-email"
                            href="mailto:nurhayat@eatechnique.com.my?Subject=Hello"
                            target="_top"
                          >
                            nurhayat@eatechnique.com.my
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="column is-6">
                      <h1 className="title is-6" id="event-title">
                        SG. UDANG, MELAKA PORT OFFICE
                      </h1>
                      <div className="columns">
                        <div className="column">
                          Bangunan Pentadbiran
                          <br />
                          Persiaran Penapisan 76300
                          <br />
                          Sungai Udang, Melaka
                        </div>
                        <div className="column">
                          T +6012 730 5224
                          <br />E &nbsp;
                          <a
                            id="footer-email"
                            href="mailto:khairulnizam@eatechnique.com.my?Subject=Hello"
                            target="_top"
                          >
                            khairulnizam@eatechnique.com.my
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="column is-6">
                      <h1 className="title is-6" id="event-title">
                        KERTIH PORT, TRENGGANU OFFICE
                      </h1>
                      <div className="columns">
                        <div className="column">
                          Bangunan Pentadbiran
                          <br />
                          Kertih Port Sdn Bhd
                          <br />
                          Lot 3633 Kawasan Tengah KM105
                          <br />
                          Jalan Kuantan - Kuala Trengganu
                          <br />
                          24300 Kertih, Kemaman
                        </div>
                        <div className="column">
                          T +603 4252 5422
                          <br />F +603 4266 0487
                        </div>
                      </div>
                    </div>

                    <div className="column is-6">
                      <h1 className="title is-6" id="event-title">
                        BINTULU PORT, SARAWAK OFFICE
                      </h1>
                      <div className="columns">
                        <div className="column">
                          Bintulu Port Sdn Bhd Marine Services
                          <br />
                          12th Miles, Tg. Kidurong Road
                          <br />
                          P.O Box 996, 97008 Bintulu, Sarawak
                        </div>
                        <div className="column">
                          T +6012 385 2224
                          <br />E &nbsp;
                          <a
                            id="footer-email"
                            href="mailto:azim@eatechnique.com.my?Subject=Hello"
                            target="_top"
                          >
                            azim@eatechnique.com.my
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </TabPanel>
            </Tabs>
          </div>
        </section>
      </div>
    </PageWrapper>
  )
}

export default ContactUsPage